<template>
	<div>
		<div class="input-group input-group-merge">
			<!-- Input -->
			<input
				:tabindex="tabindex || 2"
				:type="visible ? 'text' : 'password'"
				v-bind:value="value"
				v-on:input="$emit('input', $event.target.value)"
				class="form-control form-control-appended"
				:placeholder="placeholder"
				:class="{'is-invalid': isInvalid}"
				required
			>
			<!-- Icon -->
			<div class="input-group-append" @click="visible=!visible">
				<span class="input-group-text">
					<i class="fe fe-eye-off" v-if="!visible"></i>
					<i class="fe fe-eye" v-else></i>
				</span>
			</div>
		</div>
		<div class="invalid-feedback text-white" :class="{'d-block': isInvalid}">{{isInvalid}}</div>
	</div>
</template>

<script>
export default {
    props: ['value', 'placeholder', 'tabindex', 'isInvalid'],
    data() {
        return {
            visible: false,
        }
    }

}
</script>

<style>

</style>
