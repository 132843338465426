<template>
	<div class="d-flex align-items-center bg-auth border-top border-top-2 border-primary">
		<div class="container">
			<div class="row align-items-center justify-content-center">
				<div class="col-8 col-sm-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-md-0">
					<!-- Image -->
					<div class="text-center d-none d-md-block">
						<img src="@/assets/boolink/sally2.png" alt="..." class="img-fluid"/>
					</div>
				</div>
				<div class="col-12 col-md-5 col-xl-4 order-md-1 my-5" ref="loginForm">
					<!-- Heading <h1 class="display-4 text-center mb-3">{{$t('Log in')}}</h1>-->

					<img src="@/assets/source/logo-white.svg" alt="..." class="img-fluid mb-2"/>

					<!-- Subheading -->
					<p class="mb-5 color-white">{{ $t('to continue to your BooLink dashboard') }}</p>

					<b-alert variant="danger" :show="!!errorMessage">
						{{ $t(errorMessage) }}
						<a href="#" @click.prevent="resendVerification" v-if="resend"
						   class="text-white text-decoration-underline">Resend</a>
					</b-alert>

					<b-alert variant="success" :show="!!successMessage" dismissible>
						{{ $t(successMessage) }}
					</b-alert>

					<!-- Email address -->
					<form @submit="login" id="login-form">
						<div class="form-group">
							<!-- Label -->
							<label class="color-white">Email or Username</label>

							<!-- Input -->
							<input
								type="email"
								tabindex="1"
								v-model="data.email"
								class="form-control"
								placeholder="username or email@boolink.com"
							/>
						</div>

						<!-- Password -->
						<div class="form-group">
							<div class="row">
								<div class="col">
									<!-- Label -->
									<label class="color-white">{{ $t('Password') }}</label>
								</div>
								<div class="col-auto">
									<!-- Help text -->
									<router-link
										class="form-text small color-white"
										:to="{ name: 'password-reset'}"
									>{{ $t('Forgot password?') }}
									</router-link>
								</div>
							</div>
							<!-- / .row -->
							<!-- Input group -->
							<password-viewable v-model="data.password"
											   :placeholder="$t('Your password')"></password-viewable>
						</div>

						<!-- Submit -->
						<button-loading
							class="btn btn-lg btn-block btn-primary mb-3 custom-btn"
							type="submit"
							:loading="loading"
							@click="login()"
						>{{ $t('Log in') }}
						</button-loading>

						<div class="text-center">
							<small class="text-muted text-center">
								<router-link
									style="display: inherit;"
									class="form-text small color-white"
									:to="{ name: 'register'}"
								>{{ $t("Create an account") }}
								</router-link>
							</small>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<!-- / .row -->
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";
import PasswordViewable from '@/components/PasswordViewable.vue'
import ButtonLoading from '@/components/ButtonLoading'

export default {
	components: {
		ButtonLoading,
		PasswordViewable
	},
	data() {
		return {
			data: {},
			loading: false,
			errorMessage: null,
			resend: false,
			successMessage: null,
		}
	},
	mounted() {
		/* TODO:check if token expired */
		// this.$store.commit('clearToken');
	},
	created() {
		this.verifyTokenFunc();
	},
	methods: {
		...mapActions("authManagement", {
			resendVerifySignup: "create",
			verifyToken: "create",
		}),

		verifyTokenFunc() {
			let token = this.$route.query.token
			if (!token) return;

			this.verifyToken({
				action: "verifySignupLong",
				value: token
			})
				.then(() => {
					this.successMessage = 'Email verified :)';
				})
				.catch((error) => {
					this.successMessage = error.message;
				});
		},

		validateEmail(email) {
			const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},

		login() {
			this.errorMessage = null;
			this.loading = true;
			let promise;
			if (this.validateEmail(this.data.email)) {
				this.data.strategy = 'local';
				promise = this.$store.dispatch('auth/authenticate', this.data).then(res => {
					this.$router.push({name: 'links'});
				})
			} else {
				this.data.strategy = 'local-username';
				this.data.username = this.data.email;
				promise = this.$store.dispatch('auth/authenticate', this.data).then(res => {
					this.$router.push({name: 'links'});
				})
			}

			promise.then(data => {
				this.loading = false;
			}).catch(error => {
				console.log(error)
				this.errorMessage = error.message
				if (error.message === 'Invalid login')
					this.errorMessage = 'Incorrect login details. Please retry.';
				//this.errorMessage = 'Couldn’t find a Boolink account associated with this email. Please try again.';
				this.loading = false;
				if (error.code === 400) {
					if ( this.validateEmail(this.data.email)) {
						this.$toasted.global.success({
							message: this.$t("Please verify your email"),
						});
						setTimeout(() => {
							this.$router.push({
								name: 'verification',
								params: {
									user: this.data
								}
							})
						}, 2000)
					} else {
						this.$toasted.global.error({
							message: this.$t("Please enter your email in order to verification"),
						});
					}
				}
				//this.resend = true;
			});
		},
		resendVerification() {
			this.resendVerifySignup({
				action: "resendVerifySignup",
				value: {email: this.data.email}, // {email}, {token: verifyToken}
				notifierOptions: {}, // options passed to options.notifier, e.g. {preferredComm: 'cellphone'}
			})
				.then(() => {
					this.$toasted.global.success({
						message: this.$t("Request sent to your email"),
					});
				})
				.catch((error) => {
					this.$toasted.global.success({
						message: this.$t(error.message),
					});
				});
		}
	}
}

</script>
